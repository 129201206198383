<template>
  <!--begin::Quotation Listing-->
  <div class="visit-trip-template">
    <!--   <PageHeaderCount
        moduleType="quotation"
        :dataLoading="dataLoading"
        :statusList="statusList"
        allkey="all_quotations"
        countkey="quotation_status_count"
      ></PageHeaderCount> -->
    <ListingTemplate :customClass="'visit-trip-listing border-none'">
      <template v-slot:body>
        <ListingHeader>
          <template v-slot:content>
            <template v-if="selectedRows.length > 0">
              <v-flex class="pt-0 my-auto">
                <h1
                  class="form-title custom-nowrap-ellipsis margin-auto selected-rows-text"
                >
                  {{ totalSelected }} Selected
                </h1>
              </v-flex>
              <v-flex class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('quotation:update')">
                  <v-menu
                    content-class="custom-menu-list"
                    bottom
                    offset-y
                    transition="slide-y-transition"
                    :close-on-click="true"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        :disabled="dataLoading"
                        class="mx-2 custom-grey-border custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark left>mdi-chevron-down</v-icon>Bulk Action
                      </v-btn>
                    </template>
                    <v-list>
                      <template v-for="(more, index) in bulkActions">
                        <v-list-item
                          link
                          v-on:click="updateBulkAction(more.action)"
                          :key="index"
                        >
                          <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                            <v-icon class="icon-default-blue">{{
                              more.icon
                            }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            class="font-weight-500 font-size-14"
                            >{{ more.title }}</v-list-item-title
                          >
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </template>
                <v-btn
                  depressed
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="red lighten-1 white--text"
                  v-on:click="clearSelections"
                >
                  <v-icon dark left>mdi-close</v-icon>Clear Selections
                </v-btn>
              </v-flex>
            </template>
            <template v-else>
              <v-flex
                class="pt-0 pr-0 justify-flex-end d-flex quotation-listing-button-right"
              >
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      class="mx-2 custom-bold-button white--text"
                      color="cyan"
                      tile
                      :disabled="dataLoading"
                      :loading="dataLoading"
                      v-on:click="getRows()"
                    >
                      <v-icon>mdi-reload</v-icon>
                    </v-btn>
                  </template>
                  <span>Reload</span>
                </v-tooltip>
                <v-menu
                  max-height="400"
                  max-width="250"
                  offset-y
                  left
                  :close-on-content-click="false"
                  content-class="white-background"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading"
                      depressed
                      class="mx-2 custom-bold-button btn-tab-hide"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    class="draggable-group"
                    handle=".draggable-drag-icon"
                    v-on:change="updateVisible()"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox || Number(cols.checkbox) == 0"
                      >
                        <v-checkbox
                          dense
                          v-model="defaultColShow"
                          v-bind:value="cols.field"
                          :label="cols.headerName"
                          :disabled="Number(cols.fixed) || dataLoading"
                          color="cyan"
                          hide-details
                          class="mt-0 mb-0"
                          v-on:change="updateVisible()"
                        ></v-checkbox>
                        <v-icon class="draggable-drag-icon" right color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu>

                <v-menu
                  v-if="false"
                  max-height="400"
                  max-width="260"
                  offset-y
                  left
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    class="draggable-group"
                    handle=".draggable-drag-icon"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox || Number(cols.checkbox) == 0"
                      >
                        <v-checkbox
                          dense
                          v-model="defaultColShow"
                          v-bind:value="cols.field"
                          :label="cols.headerName"
                          :disabled="
                            (cols.fixed == 1 ? true : false) || dataLoading
                          "
                          color="cyan"
                          v-on:change="updateVisible()"
                          hide-details
                          class="pt-2 mt-0"
                        ></v-checkbox>
                        <v-icon
                          class="draggable-drag-icon"
                          small
                          right
                          color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu>
              </v-flex>
            </template>
          </template>
        </ListingHeader>
        <ListingSearchCriteria
          v-if="searchEnabled && false"
          pageModule="Quotation"
          :basicSearchFields="[
            'barcode',
            'reference',
            'job_title',
            'sales',
            'prepared_by',
            /* 'attention', */
            'company_name',
          ]"
          :allowedSearchFields="searchableArray"
        ></ListingSearchCriteria>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 90vh; position: relative"
        >
          <ListingTable
            :columnCount="columnLength()"
            :dataLoading="dataLoading"
            :rowData="rowData"
          >
            <template v-slot:thead>
              <thead>
                <tr>
                  <template v-for="cols in customThead">
                    <th
                      v-if="cols.visible"
                      :key="cols.id"
                      class="simple-table-th"
                      :class="{ 'checkbox-width-limit': cols.checkbox }"
                      :style="{ 'max-width': cols.width }"
                    >
                      <template
                        v-if="cols.checkbox && Number(cols.checkbox) == 1"
                      >
                        <v-checkbox
                          dense
                          :disabled="dataLoading"
                          v-model="selectedAll"
                          color="cyan"
                          class="hide-margin"
                          hide-details
                        ></v-checkbox>
                      </template>
                      <template v-else>
                        <div
                          v-if="cols.sortable"
                          class="simple-table-text sortable"
                          v-on:click="
                            !dataLoading
                              ? updateSorting({
                                  field: cols.field,
                                  sort: cols.sort,
                                })
                              : 'javascript:void(0)'
                          "
                        >
                          {{ cols.headerName }}
                        </div>
                        <div v-else class="simple-table-text">
                          {{ cols.headerName }}
                        </div>
                        <template v-if="cols.sort">
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'asc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-ascending</v-icon
                          >
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'desc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-descending</v-icon
                          >
                        </template>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody class="custom-border-bottom">
                <template v-if="rowData.length > 0">
                  <tr
                    v-for="(data, index) in rowData"
                    :key="index"
                    link
                    class="quotation-listing-row"
                  >
                    <template v-for="cols in customThead">
                      <td
                        v-if="cols.visible"
                        :key="cols.id"
                        :class="{
                          'simple-table-td': !cols.checkbox,
                          'checkbox-width-limit': cols.checkbox,
                        }"
                        :style="{ 'max-width': cols.width }"
                      >
                        <template
                          v-if="cols.checkbox && Number(cols.checkbox) == 1"
                        >
                          <v-checkbox
                            dense
                            v-model="selectedRows"
                            v-bind:value="data.id"
                            color="cyan"
                            class="hide-margin"
                            hide-details
                          ></v-checkbox>
                        </template>
                        <template v-else-if="cols.field == 'barcode'">
                          <div>
                            <Barcode
                              :barcode="data.barcode"
                              :id="data.id"
                            ></Barcode>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'dates'">
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Start : </b>
                            <template v-if="data.trip_start"
                              >{{ formatDate(data.trip_start) }}
                              {{ formatDateTimeStart(data.trip_start) }}
                            </template>
                            <em v-else class="text-muted"> no Start date </em>
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>End : </b>
                            <template v-if="data.trip_end"
                              >{{ formatDate(data.trip_end) }}
                              {{ formatDateTimeStart(data.trip_end) }}
                            </template>
                            <em v-else class="text-muted"> no End date </em>
                          </p>
                        </template>
                        <template v-else-if="cols.field == 'manual_dates'">
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Start : </b>
                            <template v-if="data.manual_started_at"
                              >{{ formatDate(data.manual_started_at) }}
                              {{ formatDateTimeStart(data.manual_started_at) }}
                            </template>
                            <em v-else class="text-muted"> no Start date </em>
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>End : </b>
                            <template v-if="data.manual_finished_at"
                              >{{ formatDate(data.manual_finished_at) }}
                              {{ formatDateTimeStart(data.manual_finished_at) }}
                            </template>
                            <em v-else class="text-muted"> no End date </em>
                          </p>
                        </template>
                        <template v-else-if="cols.field == 'service_form'">
                          <template
                            v-if="
                              data.service_forms &&
                              data.service_forms.length &&
                              data.service_forms.length > 0
                            "
                          >
                            <div class="d-flex">
                              <div>
                                <div>
                                  <v-icon
                                    v-if="
                                      data?.service_forms &&
                                      data.service_forms[0]?.audio_file &&
                                      data.service_forms[0].audio_file.length
                                    "
                                    color="green"
                                    small
                                    >mdi-play-circle</v-icon
                                  >
                                  <v-icon v-else style="font-size: 15px">
                                  </v-icon>
                                </div>
                                <v-btn
                                  color="red"
                                  style="margin-left: -10px"
                                  icon
                                  v-on:click.stop.prevent="
                                    openServiceForms(data)
                                  "
                                  v-if="
                                    data?.service_forms && data.service_forms[0]
                                  "
                                >
                                  <v-icon>mdi-file-pdf-box</v-icon>
                                </v-btn>
                              </div>
                              <div>
                                <div>
                                  <v-icon
                                    v-if="
                                      data?.service_forms &&
                                      data.service_forms[1]?.audio_file &&
                                      data.service_forms[1].audio_file.length
                                    "
                                    color="green"
                                    small
                                    >mdi-play-circle</v-icon
                                  >
                                  <v-icon v-else style="font-size: 15px">
                                  </v-icon>
                                </div>

                                <v-btn
                                  color="red"
                                  style="margin-left: -10px"
                                  icon
                                  v-on:click.stop.prevent="
                                    openServiceForms(data)
                                  "
                                  v-if="
                                    data?.service_forms && data.service_forms[1]
                                  "
                                >
                                  <v-icon>mdi-file-pdf-box</v-icon>
                                </v-btn>
                              </div>
                              <div>
                                <div>
                                  <v-icon
                                    v-if="
                                      data?.service_forms &&
                                      data.service_forms[2]?.audio_file &&
                                      data.service_forms[2].audio_file.length
                                    "
                                    color="green"
                                    small
                                    >mdi-play-circle</v-icon
                                  >
                                  <v-icon v-else style="font-size: 15px">
                                  </v-icon>
                                </div>

                                <v-btn
                                  color="red"
                                  style="margin-left: -10px"
                                  icon
                                  v-on:click.stop.prevent="
                                    openServiceForms(data)
                                  "
                                  v-if="
                                    data?.service_forms && data.service_forms[2]
                                  "
                                >
                                  <v-icon>mdi-file-pdf-box</v-icon>
                                </v-btn>
                              </div>
                            </div>
                            <div
                              class="font-size-16 font-weight-700"
                              style="margin-top: -8px"
                              v-if="data.service_forms.length > 3"
                            >
                              <v-chip
                                small
                                color="blue white--text"
                                label
                                v-on:click="openServiceForms(data)"
                              >
                                <span class="font-size-16 font-weight-500">
                                  +{{ data.service_forms.length - 3 }} More
                                </span>
                              </v-chip>
                              <!--   +{{ data.service_forms.length - 1 }} More -->
                            </div>
                          </template>
                          <em class="text-muted" v-else> no service form</em>
                        </template>
                        <template v-else-if="cols.field == 'engineers'">
                          <div v-on:click="openEnineers(data)">
                            <span
                              class="mr-1 font-weight-700 text-capitalize"
                              v-if="data && data.engineers && data.engineers[0]"
                            >
                              <v-avatar left small v-if="false">
                                <img
                                  v-if="
                                    data.engineers &&
                                    data.engineers[0] &&
                                    data.engineers[0]?.engineer?.profile_logo &&
                                    data.engineers[0]?.engineer?.profile_logo
                                      .file.url
                                  "
                                  :src="
                                    data.engineers[0]?.engineer?.profile_logo
                                      .file.url
                                  "
                                />
                                <img
                                  v-else
                                  src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
                                />
                              </v-avatar>
                              {{
                                data &&
                                data.engineers &&
                                data.engineers[0]?.engineer?.display_name
                              }}
                            </span>
                          </div>
                          <div v-on:click="openEnineers(data)">
                            <span
                              class="mr-1 font-weight-700 text-capitalize"
                              v-if="data && data.engineers && data.engineers[1]"
                            >
                              {{
                                data &&
                                data.engineers &&
                                data.engineers[1]?.engineer?.display_name
                              }}
                              ,
                            </span>
                          </div>
                          <div v-on:click="openEnineers(data)">
                            <span
                              v-if="data && data.engineers && data.engineers[2]"
                              class="mr-1 font-weight-700 text-capitalize"
                            >
                              {{
                                data &&
                                data.engineers &&
                                data.engineers[2]?.engineer?.display_name
                              }}
                            </span>
                          </div>
                          <div
                            class="font-size-16 font-weight-700"
                            v-if="data?.engineers?.length > 3"
                          >
                            <v-chip
                              small
                              color="blue white--text"
                              label
                              v-on:click="openEnineers(data)"
                            >
                              <span class="font-size-16 font-weight-500">
                                +{{ data?.engineers?.length - 3 }} More
                              </span>
                            </v-chip>
                            <!--   +{{ data.service_forms.length - 1 }} More -->
                          </div>
                          <em
                            v-if="data?.engineers?.length == 0"
                            class="text-muted"
                          >
                            no Technician</em
                          >
                        </template>
                        <template v-else-if="cols.field == 'action'">
                          <div class="quotation-listing-status">
                            <template v-if="data.visit_status == 1">
                              <v-chip
                                label
                                small
                                color="red"
                                outlined
                                class="mb-1"
                              >
                                Hold & CheckOut
                              </v-chip>
                            </template>
                            <template v-if="data.visit_status == 2">
                              <v-chip
                                label
                                small
                                color="red"
                                outlined
                                class="mb-1"
                              >
                                Hold & STAY
                              </v-chip>
                            </template>
                            <template v-if="data.visit_status == 3">
                              <v-chip
                                label
                                small
                                color="green"
                                outlined
                                class="mb-1"
                              >
                                Closer
                              </v-chip>
                            </template>
                            <template v-if="data.visit_status == 4">
                              <v-chip
                                label
                                small
                                color="blue"
                                outlined
                                class="mb-1"
                              >
                                Open
                              </v-chip>
                            </template>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'added_at'">
                          <v-chip
                            color="#24326d"
                            small
                            outlined
                            text-color=""
                            label
                          >
                            {{ formatDate(data.added_at) }}
                            {{ formatDateStartTime(data.added_at) }}
                          </v-chip>
                          <TableActivity
                            v-if="!lodash.isEmpty(data.added_by) && false"
                            :data="data"
                          >
                            <template v-slot:display_name>
                              {{ data.added_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.created_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDate(data.added_at) }}
                              {{ formatDateStartTime(data.added_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else>
                          <div v-html="printRowData(cols, data)"></div>
                        </template>
                      </td>
                    </template>
                  </tr>
                </template>
                <tr v-else-if="!dataLoading">
                  <td colspan="6" class="py-4">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no trip at the moment.
                    </p>
                  </td>
                </tr>
              </tbody>
            </template>
          </ListingTable>
        </perfect-scrollbar>
        <ListingFooter
          :dataLoading="dataLoading"
          :showingFrom="showingFrom"
          :showingTo="showingTo"
          :totalRows="totalRows"
          :currentPage="currentPage"
          :totalPages="totalPages"
        ></ListingFooter>
      </template>
    </ListingTemplate>
    <Dialog :common-dialog="show_engineers" :dialog-width="900">
      <template v-slot:title
        >Trip({{ trip_barcode }}) :-
        {{ detail?.customer?.display_name }}</template
      >
      <template v-slot:body>
        <v-container fluid class="py-0">
          <v-row>
            <v-col md="12" style="margin-top: -10px">
              <v-layout class="mb-2">
                <v-flex md12 class="mr-2 d-flex">
                  <div
                    class="font-weight-700 custom-headline color-custom-blue font-size-16 mr-3"
                    style="width: 120px"
                  >
                    Visit Title <span style="margin-left: 26px">:</span>
                  </div>
                  <template v-if="visit?.title">
                    <div class="mr-2 font-weight-600 text-capitalize">
                      {{ visit?.title }}
                    </div>
                  </template>
                </v-flex>
              </v-layout>
              <v-layout class="mb-2">
                <v-flex md12 class="mr-2 d-flex">
                  <div
                    class="font-weight-700 custom-headline color-custom-blue font-size-16 mr-3"
                    style="width: 120px"
                  >
                    Site Location :
                  </div>
                  <template v-if="detail?.property?.id">
                    <div
                      class="mr-2 font-weight-600 text-capitalize"
                      v-if="detail?.property"
                    >
                      {{ detail?.property?.street_1 }},

                      <template v-if="detail?.property?.street_2">
                        {{ detail?.property?.street_2 }},
                      </template>
                      <template v-if="detail?.property?.unit_no">
                        {{ detail?.property?.unit_no }},
                      </template>

                      {{ detail?.property?.country }}
                      <template
                        v-if="
                          detail?.property &&
                          detail?.property?.zip_code != '000000'
                        "
                      >
                        , {{ detail?.property?.zip_code }}
                      </template>
                    </div>
                  </template>
                </v-flex>
              </v-layout>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12" class="pt-0 custom-listing-table">
              <v-simple-table class="inner-simple-table" fixed-header>
                <template v-slot:default>
                  <thead
                    style="
                      background-color: rgb(243, 246, 249);
                      color: rgb(36, 50, 109);
                    "
                  >
                    <tr class="custom-border-bottom">
                      <td class="font-size-16 font-weight-700 p-0">
                        Technician Info
                      </td>
                      <td class="font-size-16 font-weight-700">Type</td>
                      <td class="font-size-16 font-weight-700">Added By</td>
                      <td class="font-size-16 font-weight-700">Added At</td>
                      <td class="font-size-16 font-weight-700">Total Time</td>
                      <td class="font-size-16 font-weight-700">Timestamp</td>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="lodash.isEmpty(trip_engineers) === false">
                      <tr
                        v-for="(row, index) in trip_engineers"
                        :key="index"
                        :class="{ 'orange_lighten-5': index % 2 == 0 }"
                      >
                        <td>
                          <template v-if="row.engineer.barcode">
                            <div>
                              <label class="font-size-14 mb-0"
                                ><b>
                                  <v-icon size="18">mdi-pound</v-icon>
                                </b></label
                              >
                              {{ row.engineer.barcode }}
                            </div>
                          </template>

                          <template v-if="row.engineer.display_name">
                            <div>
                              <label class="font-size-14 mb-0"
                                ><b>
                                  <v-icon size="18">mdi-account-circle </v-icon>
                                </b></label
                              >
                              {{ row.engineer.display_name }}
                            </div>
                          </template>
                        </td>
                        <td class="py-2">
                          <v-chip
                            small
                            color="red"
                            v-if="row?.plateform_type == 'app'"
                            label
                            text-color="white"
                          >
                            App</v-chip
                          >
                          <v-chip
                            small
                            color="blue"
                            v-else
                            label
                            text-color="white"
                            >Web</v-chip
                          >
                        </td>
                        <td class="py-2">
                          <span
                            class="font-weight-700 text-capitalize font-size-14"
                            v-if="
                              row && row.added_by && row.added_by.display_name
                            "
                          >
                            {{ row.added_by.display_name }}
                          </span>
                        </td>
                        <td class="py-2">
                          <v-chip
                            color="#24326d"
                            small
                            outlined
                            text-color=""
                            label
                          >
                            {{ formatDate(row.added_at) }}
                            {{ formatDateStartTime(row.added_at) }}
                          </v-chip>
                        </td>
                        <td class="py-2">
                          <span
                            class="font-weight-700 font-size-14"
                            v-if="totalTime(row?.engineer?.main_manual)"
                          >
                            {{ totalTime(row?.engineer?.main_manual) }}</span
                          >
                          <em v-else class="text-muted"> no total time</em>
                        </td>
                        <td class="py-2">
                          <v-btn
                            class="mx-2 custom-bold-button white--text"
                            color="red"
                            depressed
                            @click="getToRoute(row.engineer.id)"
                          >
                            <v-icon left>mdi-timetable</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                    <template v-else>
                      <tr>
                        <td :colspan="5">
                          <p
                            class="m-0 row-not-found text-center font-weight-500 font-size-16"
                          >
                            Sorry! No Schedule(s) Found.
                          </p>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="show_engineers = false"
        >
          Close
        </v-btn>
      </template>
    </Dialog>
    <Dialog :common-dialog="show_service_forms" :dialog-width="1200">
      <template v-slot:title
        >Trip({{ trip_barcode }}) :-
        {{ detail?.customer?.display_name }}</template
      >
      <template v-slot:body>
        <ServiceFormDetail
          :detail="detail"
          :visit="visit"
          :trip-id="trip_id"
          :customer="detail.customer"
          :property="detail.property"
          :billing="detail.billing"
          :contact-person="detail.property_person"
          :page-loading="pageLoading"
          is-visit-detail
        >
        </ServiceFormDetail>
      </template>
      <template v-slot:action>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="show_service_forms = false"
        >
          Close
        </v-btn>
      </template>
    </Dialog>
  </div>

  <!--end::Quotation Listing-->
</template>

<script>
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import Barcode from "@/view/pages/partials/Barcode.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import ServiceFormDetail from "@/view/pages/job/partials/Service-Form-Detail.vue";
import { GET } from "@/core/services/store/request.module";
//import { map } from "lodash";
import moment from "moment-timezone";
export default {
  mixins: [CommonMixin, ListingMixin],
  name: "visit-trip-list",
  data() {
    return {
      dates: [],
      pageModule: "visit-trip",
      routeAPI: `visit/${this.visitId}/trips`,
      importDialog: false,
      show_engineers: false,
      show_service_forms: false,
      trip_barcode: null,
      trip_id: 0,
      status: "all",
      statusList: [],
      trip_engineers: [],
      customThead: [],
      moreActions: [
        {
          title: "Import Quotation(s)",
          action: "import_items",
          divider: true,
          icon: "mdi-database-import",
        },
        /*{
                  title: "Export Quotation(s)",
                  action: "export_items",
                  divider: false,
                  icon: "mdi-database-export",
                },*/
        /*{
                  title: "Export Current View",
                  action: "export_current_view",
                  divider: true,
                  icon: "mdi-database-export",
                },*/
        {
          title: "Refresh List",
          action: "refresh_list",
          divider: true,
          icon: "mdi-refresh",
        },
      ],
      bulkActions: [
        {
          title: "Mark as Draft",
          icon: "mdi-check-all",
          action: "draft",
        },
        {
          title: "Mark as Accepted",
          icon: "mdi-check-all",
          action: "accepted",
        },
        {
          title: "Mark as Rejected",
          icon: "mdi-check-all",
          action: "rejected",
        },
        {
          title: "Mark as Pending",
          icon: "mdi-email-receive",
          action: "pending",
        },
        /*{
                  title: "Send as Email",
                  icon: "mdi-email-send",
                  action: "sent"
                },*/
        {
          title: "Mark as Cancelled",
          icon: "mdi-check-all",
          action: "cancelled",
        },
        {
          title: "Mark as Closed",
          icon: "mdi-check-all",
          action: "closed",
        },
      ],
    };
  },
  components: {
    /*   PageTips, */
    draggable,
    Dialog,
    /*  PageHeaderCount, */
    Barcode,
    TableActivity,
    ListingTemplate,
    ListingFooter,
    ListingTable,
    ListingHeader,
    ListingSearchCriteria,
    ServiceFormDetail,
  },
  props: {
    pageLoading: {
      type: Boolean,
      default: false,
    },
    visitId: {
      type: Number,
      default: 0,
    },
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    visit: {
      type: Object,
      default() {
        return new Object();
      },
    },
  },
  methods: {
    totalTime(data) {
      if (data) {
        const startDate = new Date(data.start_date);
        const endDate = new Date(data.end_date);

        const diff = endDate.getTime() - startDate.getTime();
        const seconds = Math.floor(diff / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const weeks = Math.floor(days / 7);
        const months = Math.floor(days / 30);

        let totalTimeString = "";
        if (months > 0) {
          totalTimeString += `${months} Month `;
        }
        if (weeks > 0) {
          totalTimeString += `${weeks} Week `;
        }
        if (days % 30 > 0) {
          totalTimeString += `${days % 30} Day `;
        }
        if (hours % 24 > 0) {
          totalTimeString += `${hours % 24} Hour `;
        }
        if (minutes % 60 > 0) {
          totalTimeString += `${minutes % 60} Minute `;
        }
        /*  if (seconds % 60 > 0) {
        totalTimeString += `${seconds % 60} second(s)`;
      } */

        return totalTimeString.trim();
      }
    },
    getVisitTripEng(id) {
      const _this = this;
      _this.dataLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "visit-trip-engineer/" + id,
        })
        .then(({ data }) => {
          this.trip_engineers = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.dataLoading = false;
        });
    },
    getToRoute(id) {
      const path = this.$router.resolve({
        name: "admin.profile.visit-timesheet",
        query: {
          visit: this.visitId ? this.visitId : 0,
          filter_engineer: id,
          status: "all",
        },
      });
      const newpath = path.href.replace(/^\//, "");
      const url = this.$assetURL(newpath);
      window.open(url, "_blank");
    },
    downloadFile(url, prepend_url) {
      if (!url) {
        return false;
      }
      if (prepend_url) {
        url = `https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/${url}`;
      }
      window.open(url, "_blank");
    },
    goToServiceForm(id) {
      let query = { ...this.$route.query };
      query.tab = "service-form";
      query.trip_id = id;
      this.$router.push({ query });
    },
    formatDateTimeStart(row) {
      return moment(row).format("hh:mm A");
    },
    formatDateStartTime(row) {
      return moment(row).format("hh:mm A");
    },
    openEnineers(tripData) {
      this.trip_barcode = tripData.barcode;
      /* this.trip_engineers = engineers;
       */
      this.getVisitTripEng(tripData?.id);
      this.show_engineers = true;
    },
    openServiceForms(tripData) {
      this.trip_barcode = tripData.barcode;
      this.trip_id = tripData.id;
      this.show_service_forms = true;
    },
    viewEngineer(params) {
      this.$router.push(
        this.getDefaultRoute("engineer.detail", {
          params: { id: params.id },
        })
      );
    },
  },
  mounted() {},
  beforeMount() {
    const _this = this;

    //_this.applyColState();

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 50;
  },
};
</script>
